import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container, Row, Col, Image, Table } from "react-bootstrap"

import weldedWireImage from "../images/welded-wire-mesh-rolls.jpg"

export default () => (
  <Layout>
    <SEO title="Welded Wire Mesh Rolls" />
    <Container>
      <Row>
        <Col lg={8}>
          <h1>Welded Wire Mesh Rolls</h1>
          <p>
            Resistance welded at every wire section creating a square or slotted openings, welded wire mesh is rigid and strong.
          </p>
          <p>
            Typical applications for welded wire mesh include security screens, filtration support screens, safety screens, safety guards, animal cages, food processing, incubators, handrail in-fill panels, tree guards, elevator shaft lining, construction reinforcement screens and, by the way, fencing.
          </p>
          <p>
            <strong>100 Foot Long Rolls are Stocked In:</strong>
          </p>
          <ul>
            <li>Galvanized Before Welded Wire</li>
            <li>Galvanized After Welded Wire</li>
            <li>PVC Coated / Welded Wire</li>
            <li>Stainless Steel Welded Wire</li>
          </ul>
        </Col>
        <Col lg={4}>
          <Image src={weldedWireImage} alt="Welded Wire Mesh Example" fluid />
        </Col>
      </Row>
      <hr />
      <h2>Available Openings / Gauges (varies by style)</h2>
      <p><small>Welded Wire Mesh : Rolls</small></p>
      <Table striped bordered responsive className="noWrap">
        <thead>
        <tr>
            <th>Mesh Size</th>
            <th>Gauge(s)</th>
            <th>Mesh Size</th>
            <th>Gauge(s)</th>
            <th>Mesh Size</th>
            <th>Gauge(s)</th>
        </tr>
        </thead>
        <tbody>
            <tr>
                <td>1/2″ x 1/2″</td>
                <td>19 or 16</td>
                <td>1″ x 1″</td>
                <td>14, 16, or 12.5</td>
                <td>2″ x 4″</td>
                <td>14 or 12.5</td>
            </tr>
            <tr>
              <td>1/2″ x 1″</td>
              <td>16</td>
              <td>1″ x 2″</td>
              <td>14</td>
              <td>3″ x 3″</td>
              <td>11</td>
            </tr>
            <tr>
              <td>1/2″ x 3″</td>
              <td>14 or 12.5</td>
              <td>2″ x 2″</td>
              <td>14 or 12.5</td>
              <td>4″ x 4″</td>
              <td>12.5</td>
            </tr>
          </tbody>
        </Table>
    </Container>
  </Layout>
)
